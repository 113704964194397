import {React, useEffect, useState} from 'react';
import axios from 'axios';
import { Button, Input, Row, Col, Container } from 'reactstrap';
import MainNavbar from './Navbar';
import AlarmToast from './AlarmToast';

function Pickup() {
    const [customers, setCustomers] = useState([]);
    const [customersBackup, setCustomersBackup] = useState([]);
    const [err, setErr] = useState(null);
    const [selectedCustomer, setSelectedCutomer] = useState(null);
    
    // TODO: Add some feedback from data fetch

    useEffect(() => {
        setErr("Getting customers...")
        axios.get("/get_customers").then((res) => {
            setCustomers(res.data);
            setCustomersBackup(res.data);
            setErr(null);
            setSelectedCutomer(res.data[0]);
        }).catch((err) => {
            console.error("Error fetching customers:", err);
          });
    }, [])

    return(
        <>
            <MainNavbar/>
            <Container>
            {err? 
                <Row className='mt-1'>
                    <Col md={{offset: 5, size: 5}}sm={{offset: 4, size: 5}} xs={{offset: 1, size: 11}}>
                        <h6>{err}</h6>
                    </Col>
                </Row> : null}
                <Row>
                    <Col>
                        <Input id="textInput" type='text' placeholder='Customer' onChange={(e) => {
                            if (e.target.value.length === 0) {
                                setCustomers(customersBackup);
                            } else {
                                // prev?.filter(elem => elem.includes(e.target.value));
                                const selectedValues = customers.filter((elem) => elem.toLowerCase().includes(e.target.value.toLowerCase()));
                                setCustomers(selectedValues);
                                setSelectedCutomer(selectedValues[0]);
                            }

                        }}/>
                    </Col>
                </Row>
                <Row className='mt-1'>
                    <Col>
                        <Input id="inputSelect" type='select' onChange={(e) => {
                            setSelectedCutomer(e.target.value);
                            }
                        }>
                        {customers?.map((customer) => (<option key={customer} >{customer}</option>))}
                        </Input>
                    </Col>
                </Row>
                <Row className='mt-1'>
                    <Col xs='12'>
                        <Button color='success' className='w-100 me-1' onClick={(e) => {
                            if (selectedCustomer) {
                                axios.get(`/pickup/?customer=${selectedCustomer}`);
                                setCustomers(customersBackup);
                                setSelectedCutomer(null);
                                document.getElementById("textInput").value = "";
                                // document.getElementById("inputSelect").value = "";
                            }
                        }
                        }>
                            {(selectedCustomer == null)? "PickUp": selectedCustomer}
                        </Button>
                    </Col>
                    {/* <Col xs='6'>
                        <Button color='secondary' className='w-100' onClick={() => setCustomers(customersBackup)}>
                            Update
                        </Button>
                    </Col> */}
                </Row>
            </Container>
        </>
    )
}

export default Pickup;